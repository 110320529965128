<template>
  <div>
    <div :id="'tinymce' + tinymceFlag" style="width:700px;height:700px;"></div>
  </div>
</template>

<script>
// https://www.fujieace.com/tinymce/activeeditor-getcontent-setcontent.html
// 获取内容：tinymce.activeEditor.getContent()
// 设置内容：tinymce.activeEditor.setContent("需要设置的编辑器内容")

import aliOSS from '@/utils/aliOSS';
export default {
  data() {
    return {
      tinymceFlag: Date.parse(new Date()) / 1000,
    };
  },
  created() {},
  mounted() {
    console.log(this.tinymceFlag, 'this.tinymceFlag');
    this.$nextTick(() => {
      this.init();
    });
  },
  methods: {
    init() {
      tinymce.init({
        selector: '#tinymce' + this.tinymceFlag,
        language: 'zh_CN', // 需要在官网自己下载一个全局的langs包。同时我提供的powerpaste本身自带一个langs包里面含中文，所以可以100%支持中文。
        plugins: [
          'powerpaste', // plugins中，用powerpaste替换原来的paste
          'image',
        ],
        toolbar: 'undo redo | fullscreen | formatselect alignleft aligncenter alignright alignjustify | link unlink | numlist bullist | image media table | fontselect fontsizeselect forecolor backcolor | bold italic underline strikethrough | indent outdent | superscript subscript | removeformat |',
        powerpaste_word_import: 'propmt', // 参数可以是propmt, merge, clear，效果自行切换对比
        powerpaste_html_import: 'propmt', // propmt, merge, clear
        powerpaste_allow_local_images: true,
        paste_data_images: true,
        images_upload_handler: async (blobInfo, success, failure) => {
          // 这个函数主要处理word中的图片，并自动完成上传；
          // ajaxUpload是自己定义的一个函数；在回调中，记得调用success函数，传入上传好的图片地址；
          // blobInfo.blob() 得到图片的file对象；
          const base64 = await this.getBase64(blobInfo.blob()); // 转为base64
          const result = await aliOSS(base64);
          if (result) {
            success(result.url);
          } else {
          }
        },
        // tinymce的其他配置参数
      });
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        let fileResult = '';
        reader.readAsDataURL(file);
        //开始转
        reader.onload = () => {
          fileResult = reader.result;
        };
        //转失败
        reader.onerror = error => {
          reject(error);
        };
        //结束 resolve
        reader.onloadend = () => {
          resolve(fileResult);
        };
      });
    },
  },
  beforeDestroy() {
    tinymce.remove('#tinymce');
  },
};
</script>

<style lang="scss" scoped></style>
